import React from "react"
import { Link } from "gatsby"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faPhoneAlt,
  faEnvelopeOpenText,
  faHome,
} from "@fortawesome/free-solid-svg-icons"

//import axios from "axios"

import SEO from "../components/seo"

import Header from "../components/header"
import Footer from "../components/footer"
import Heroimage from "../components/heroimage"
import Holzbildwerk from "../components/holzbildwerk"
import Tafelmalerei from "../components/tafelmalerei"
import Altarretabel from "../components/altarretabel"
import Wandmalerei from "../components/wandmalerei"
import Stuckbildwerk from "../components/stuckbildwerk"
import Architektur from "../components/architektur"
import Sakralraum from "../components/sakralraum"
import Profanbau from "../components/profanbau"
import Publikation from "../components/publikation"
import Leinwandgemaelde from "../components/leinwandgemaelde"
import Rekonstruktion from "../components/rekonstruktion"
import Untersuchung from "../components/untersuchung"
import Werksprozess from "../components/werksprozess"
import AstridImg from "../components/astrid"
import SchachaidaImg from "../components/schachaida"
import KljajicImg from "../components/kljajic"
import KloebleImg from "../components/kloeble"
import MeierImg from "../components/meier"
import EgerlandImg from "../components/egerland"
import RibeiroImg from "../components/ribeiro"
import TschoepeImg from "../components/tschoepe"
import FersethImg from "../components/ferseth"
import Analyse from "../components/analyse"
import Kartierung from "../components/kartierung"

import "./index.css"

const IndexPage = () => {
  /* const [buttonText, setButtonText] = useState("Nachricht senden") */

  /*
  const onSubmitForm = event => {
    event.preventDefault()
    setButtonText("Vielen Dank, wir kontaktieren sie baldmöglichst")
  } */

  return (
    <>
      <SEO
        description="restaura ratisbona, Ihr Team für Restaurierungen. Spezialisiert auf Wandrestauration, Austattungsrestauration"
        title="restaura ratisbona, Ihr Team für Restaurierungen"
        lang="de"
      />
      <Header />
      <section className="indexSection" id="restaura-ratisbona-startseite">
        <Heroimage className="start">
          <div className="dark">
            <h1>Wir bewahren Ihre Kunstgüter</h1>
            <div>
              <Link to="/aktuelles">Aktuelles</Link>
              <Link to="/#kontakt">Kontakt</Link>
            </div>
          </div>
        </Heroimage>
        <div>
          <h2 className="indexHeadline" id="philosophie">
            Philosophie
          </h2>
          <div className="philosophieDiv">
            <p className="indexText">
              Unser Maßstab für den Erhalt von Kunstwerken basiert auf den 1964
              in der Charta von Venedig festgehaltenen Prinzipien, die den Wert
              von Kunstgütern definieren. Nach deren Präambel sind Kunstwerke
              als lebendige Zeugnisse anzusehen, die verschiedene Traditionen
              repräsentieren und dadurch eine geistige Botschaft aus der
              Vergangenheit in unsere heutige Zeit transportieren (Charta von
              Venedig, 31. Mai 1964).
            </p>
          </div>
        </div>
        <div>
          <h2 className="indexHeadline" id="referenzen">
            Referenzen
          </h2>
          <div className="references">
            <Link to="/holzbildwerke">
              <Holzbildwerk className="referenceCard">
                <div className="dark">
                  <h2>Holzbildwerke</h2>
                </div>
              </Holzbildwerk>
            </Link>
            <Link to="/tafelmalerei">
              <Tafelmalerei className="referenceCard">
                <div className="dark">
                  <h2>Tafelmalerei</h2>
                </div>
              </Tafelmalerei>
            </Link>
            <Link to="/altarretabel">
              <Altarretabel className="referenceCard">
                <div className="dark">
                  <h2>Altarretabel</h2>
                </div>
              </Altarretabel>
            </Link>
            <Link to="/wandgemaelde">
              <Wandmalerei className="referenceCard">
                <div className="dark">
                  <h2>Wandmalerei</h2>
                </div>
              </Wandmalerei>
            </Link>
            <Link to="/stuckbildwerk">
              <Stuckbildwerk className="referenceCard">
                <div className="dark">
                  <h2>Stuckbildwerke</h2>
                </div>
              </Stuckbildwerk>
            </Link>
            <Link to="/architekturflaeche">
              <Architektur className="referenceCard">
                <div className="dark">
                  <h2>Architekturoberfläche</h2>
                </div>
              </Architektur>
            </Link>
            <Link to="/sakralraum">
              <Sakralraum className="referenceCard">
                <div className="dark">
                  <h2>Sakralraum</h2>
                </div>
              </Sakralraum>
            </Link>
            <Link to="/schlossinterieur">
              <Rekonstruktion className="referenceCard">
                <div className="dark">
                  <h2>Schlossinterieur</h2>
                </div>
              </Rekonstruktion>
            </Link>
            <Link to="/profanbau">
              <Profanbau className="referenceCard">
                <div className="dark">
                  <h2>Profanbau</h2>
                </div>
              </Profanbau>
            </Link>
            <Link to="/untersuchung">
              <Untersuchung className="referenceCard">
                <div className="dark">
                  <h2>Untersuchung</h2>
                </div>
              </Untersuchung>
            </Link>
            <Link to="/werksprozess">
              <Werksprozess className="referenceCard">
                <div className="dark">
                  <h2>Werksprozess</h2>
                </div>
              </Werksprozess>
            </Link>
            <Link to="/publikation">
              <Publikation className="referenceCard">
                <div className="dark">
                  <h2>Publikation</h2>
                </div>
              </Publikation>
            </Link>
            <Link to="/leinwandgemaelde">
              <Leinwandgemaelde className="referenceCard">
                <div className="dark">
                  <h2>Leinwandgemälde</h2>
                </div>
              </Leinwandgemaelde>
            </Link>
          </div>
        </div>
        <Link to="/referenzen">
          <button className="referenzenButton pointer">
            Zur tabellarischen <nobr>Referenzliste</nobr>
          </button>
        </Link>
        <div>
          <h2 className="indexHeadline" id="leistungen">
            Leistungen
          </h2>
          <div className="leistungenDiv">
            <div className="leistungDiv">
              <h2 className="leistungenH2 wandH2">
                Wandmalerei und Architekturflaechen
              </h2>
              <ul className="wandLeistungen">
                <li>Behandlung von Mikroorganismen und Salzreduzierung</li>
                <li>Stuckkonsolidierung und Hohlstellenhinterfüllung</li>
                <li>Rissverschluss mit artgleichen Materialien</li>
                <li>Fehlstellenergänzung mit geeigneten Mörteln</li>
                <li>Schutz der Malschicht mit Cyclododecan</li>
                <li>
                  Historische Arbeitsverfahren mit mineralischen Bindemitteln
                </li>
                <li>Kartierung des freskalen Herstellungsprozesses</li>
              </ul>
            </div>
            <div className="leistungDiv">
              <h2 className="leistungenH2">
                Holzbildwerke und Ausstattungsteile
              </h2>
              <ul className="wandLeistungen">
                <li>Behandlung von Mikroorganismen</li>
                <li>
                  Konservierung der Fassung und Metallauflagen, insbesondere mit{" "}
                  <nobr>Hausenblasenleim</nobr>
                </li>
                <li>Holzkonsolidierung</li>
                <li>
                  Historische Arbeitsverfahren, beispielweise Vergoldung
                  Marmorierung, <nobr>Polierweißfassung</nobr>
                </li>
                <li>
                  Farbfassungen mit historischen Pigmenten, wie Smalte, Malachit
                  und Azurit
                </li>
                <li>
                  Im Bedarfsfall holzbildhauerische Ergänzung und Teilergänzung
                  mit <nobr>Stäbchenverleimung</nobr>
                </li>
              </ul>
            </div>
            <div className="leistungDiv">
              <h2 className="leistungenH2">
                Allgemeine Maßnahmen beider Fachbereiche
              </h2>
              <ul className="wandLeistungen">
                <li>
                  Visualisierung von Fassungen in unterschiedlichen
                  Zeitstellungen
                </li>
                <li>
                  Untersuchung, Bestandsdokumentation und Schadensbeschreibung
                </li>
                <li>Zusammenarbeit mit Materialprüfanstalten</li>
                <li>
                  Konzepterstellung einschließlich Versuchsreihen und
                  Musterachsen
                </li>
                <li>
                  Substanzsicherung und differenziertes Reinigungsverfahren
                </li>
                <li>
                  Ergänzung in unterschiedlichen Retuschearten, wie
                  Vollretusche, vorzugsweise <nobr>Rigatino/Tratteggio,</nobr>{" "}
                  selezione cromatica und astrazione cromatica ausgeführt in{" "}
                  <nobr>adäquaten</nobr> Bindemittelsystemen
                </li>
                <li>
                  Historische Bauforschung mit kunstgeschichtlicher Einordnung
                </li>
                <li>Computergestützte Kartierung von Bestand und Maßnahmen</li>
                <li>Fotografische und textliche Dokumentation</li>
                <li>Erstellung eines Raumbuches</li>
                <li>Archivrecherche und Auswertung des Quellenmaterials</li>
                <li>Begleitung von Restaurierungsvorhaben</li>
                <li>Inventarisation</li>
                <li>Öffentlichkeitsarbeit</li>
              </ul>
            </div>
          </div>
        </div>
        <div>
          <h2 className="indexHeadline">Arbeitsweise</h2>
          <div className="philosophieDiv">
            <div className="analyse">
              <Analyse />
            </div>
            <p className="indexText">
              Gemäß der Prämisse der Sicherstellung des kulturellen Erbes gilt
              es, den angetroffenen Bestand mit allen Fassungsbeständen zu
              dokumentieren und zu erhalten. Neben der technologischen und
              kunstgeschichtlichen Erforschung von Kunst- und Kulturgut widmen
              wir uns primär der Erhaltung und Pflege von Kunstdenkmälern. Die
              Konservierung des Bestandes steht immer im Vordergrund. Inwieweit
              eine über die Konservierung hinausgehende Ergänzungsmaßnahme zur
              Anwendung kommen soll, muss im Einzelfall entschieden werden.
              Deshalb werden bei der Erstellung eines Restaurierungskonzeptes
              möglichst viele Sichtweisen in Erwägung gezogen werden.
              <div className="kartierung">
                <Kartierung />
              </div>
              Diese betreffen die Ästhetik, Zeitstellung, Schadensumfang und
              Nutzung des Kunstwerks. Die Form der Ergänzung muss bei jedem
              Kunstwerk neu abgewogen werden, damit ein solcher Eingriff das
              Werk nicht unangemessen verfälscht. Um die Ergänzung transparent
              zu machen, werden diese in ablesbarer Retuchetechnik ausgeführt
              bzw. graphisch dokumentiert. Dadurch lassen sie sich vom
              historischen Bestand nachvollziehbar unterscheiden. Darüber hinaus
              ist die Patina eines natürlich gealterten Kunstwerks zu
              respektieren.
            </p>
          </div>
        </div>

        <div className="contactDiv">
          <h2 className="indexHeadline" id="kontakt">
            Kontaktieren Sie uns!
          </h2>
          <p className="indexText">
            Wir freuen uns über Ihren Besuch unserer Website und das Interesse
            an unseren Leistungen. Gerne beraten wir Sie als Vertreter/innen
            öffentlicher oder kirchlicher Institutionen oder als privater
            Auftraggeber. Wenn Sie uns per E-Mail, telefonisch oder postalisch
            kontaktieren, kommen wir schnellstmöglich auf Sie zu, um Sie bei
            Ihren Vorhaben zu unterstützen.
          </p>
          <div className="contact">
            <div className="contactOptions">
              <div className="contactPhone">
                <FontAwesomeIcon
                  icon={faPhoneAlt}
                  size="2x"
                  style={{ color: "#2b4d6c" }}
                />
                <div>
                  <a href="tel:+499416963520">Festnetz: 0941 / 6963520</a>
                  <a href="tel:+4917632756571">Mobil: 0176 / 32756571</a>
                </div>
              </div>
              <div className="contactMail">
                <FontAwesomeIcon
                  icon={faEnvelopeOpenText}
                  size="2x"
                  style={{ color: "#2b4d6c" }}
                />
                <div>
                  <a href="mailto:restaura.astridmendes@gmail.com">
                    E-mail: <br />
                    <nobr>restaura.astridmendes</nobr>
                    <wbr />
                    @gmail.com
                  </a>
                  <a href="mailto:kontakt@restaura-ratisbona.de">
                    E-mail: <br />
                    <nobr>kontakt</nobr>
                    <wbr />
                    @restaura-ratisbona.de
                  </a>
                </div>
              </div>
              <div className="contactLocal">
                <FontAwesomeIcon
                  icon={faHome}
                  size="2x"
                  style={{ color: "#2b4d6c" }}
                />
                <p>
                  restaura ratisbona
                  <br />
                  Astrid Mendes da <nobr>Vitoria</nobr>
                  <br />
                  Eilsbrunner Straße 17
                  <br />
                  93161 Sinzing
                </p>
              </div>
            </div>
            <div className="form">
              <form
                id="kontakt-formular"
                method="post"
                data-netlify="true"
                netlify-honeypot="bot-field"
                name="Kontakt"
                action="/"
              >
                <fieldset>
                  <input type="hidden" name="bot-field" />
                  <input type="hidden" name="form-name" value="Kontakt" />
                  <label htmlFor="name">
                    Name
                    <input id="name" name="Name" type="text" required />
                  </label>
                  <label htmlFor="email">
                    E-Mail
                    <input id="email" name="Email" type="email" required />
                  </label>
                  <label htmlFor="message">
                    Nachricht
                    <textarea id="message" name="Nachricht" rows="4" required />
                  </label>
                  <button type="submit" className="pointer">
                    {/* {buttonText} */} Nachricht senden
                  </button>
                </fieldset>
              </form>
            </div>
          </div>
        </div>
        <div>
          <h2 className="indexHeadline">Unsere Auftraggeber</h2>
          <div className="leistungenDiv">
            <div className="leistungDiv">
              <ul className="wandLeistungen">
                <li>Diözesanbauämter</li>
                <li>Staatliche Bauämter</li>
                <li>
                  Bayerische Verwaltung der staatlichen Gärten, Schlösser und
                  Seen (BSV)
                </li>
                <li>Staatliche Schlösser und Gärten in Baden-Württemberg</li>
                <li>Gärten der Welt, Berlin Marzahn</li>
                <li>Privatkunden</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="teamDiv" id="team">
          <h2 className="indexHeadline">Team</h2>
          <div className="teamMembers">
            <div className="teamCard">
              <div className="teamImg">
                <AstridImg />
              </div>
              <h3 className="teamInfoHeadline">Astrid Mendes - Teamleitung</h3>
              <p className="teamInfoP">
                Astrid Mendes leitet mit ihrer 30jährigen Erfahrung das Team um
                restaura ratisbona. Sie ist auf die Restaurierung von
                Wandmalerei, Architekturfassung und das Interieur von Kirchen,
                Schlössern und Profanbauten spezialisiert. Dabei legt sie
                besonderen Wert auf die Zusammenführung von Theorie und Praxis.
                Dazu gehört auf der einen Seite die sorgfältige Recherche,
                Bestandssicherung und Konzepterstellung. Andererseits die
                Auseinandersetzung mit den ihr anvertrauten Objekten vor Ort bei
                der Restaurierung. Frau Mendes ist langjähriges Mitglied
                diverser Fachverbände wie dem Verband deutscher Kunsthistoriker
                e.V. oder dem Verband der Restauratoren(VDR) und ergänzte
                zuletzt ihre fachliche Kompetenz durch ein wissenschaftliches
                Studium im Fach Präventive Konservierung und Restaurierung an
                der HAWK Hildesheim sowie durch ihr Studium der Kunstgeschichte
                an der Universität Regensburg, welches sie mit dem Titel Master
                of Arts abschloss. Neben ihrer Tätigkeit als Restauratorin
                widmet sie sich der Weitergabe ihrer Profession (extern als
                Dozentin für verschiedene Institutionen, intern an
                Praktikant/innen).
              </p>
            </div>
            <div className="teamCard">
              <div className="teamImg">
                <SchachaidaImg />
              </div>
              <h3 className="teamInfoHeadline">
                Juri Schachaida - Holzrestaurator
              </h3>
              <p className="teamInfoP">
                Juri Schachaida ist diplomierter Holzrestaurator und
                Kunsttischler. Den Schwerpunkt seiner Tätigkeit bildet die
                Restaurierung antiker Möbel aller Epochen und sakraler
                Holzobjekte. Zu den restaurierten sakralen Objekten zählen die
                furnierten Barockaltäre der Wallfahrtskirchen St. Anton in
                Ratzenhofen und St. Marien in Pertolzhofen sowie die Balustraden
                und Kommunionbänke der Dreifaltigkeitskirche Kappl in
                Waldsassen. Im Restaurierungsbereich antiker Möbel reicht sein
                Spektrum von der Bearbeitung massiver bis hin zu furnierten,
                intarsierten Objekten. Die Detailaufgaben sind Bestandserhalt,
                Fehlstellenergänzung und Oberflächenbearbeitung nach originalem
                Vorbild unter Berücksichtigung historischer Substanz.
              </p>
            </div>
            <div className="teamCard">
              <div className="teamImg">
                <FersethImg />
              </div>
              <h3 className="teamInfoHeadline">Elin Ferseth - Restauratorin</h3>
              <p className="teamInfoP">
                Restauratorin Elin Ferseth hat an der Fachhochschule in Erfurt,
                Konservierung und Restaurierung von Kunst- und Kulturgut im
                Fachbereich Wandmalerei und Architekturfassung studiert. Sie
                wird für uns in diesen beiden Arbeitsfeldern tätig werden. Frau
                Ferseth bedient sich bei der Bestandserfassung der Programme
                AutoCAD, Metigo Map sowie für Visualisierung Sketchup und Rhino.
                Ein Studienschwerpunkt war die naturwissenschaftliche Bestimmung
                von Pigmenten und Putzsysteme einer provinzialrömischen
                Wandmalerei aus dem Römerpark Straubing. Mit der Erstellung des
                Restaurierungskonzeptes für eine Stuckdecke (um 1800) setzte sie
                sich im Weimarer Stadtschloss, im Rahmen ihrer Bachelorthesis,
                auseinander. Darüber hinaus entwickelte sie innerhalb einer
                Studienarbeit ein Konzept für die Reinigung und Ergänzung von
                Stuckmarmor für den Marmorsaal (1743–1746) in Schloss Molsdorf.
                Weiter bringt sie Erfahrung in der Steinkonservierung mit.
              </p>
            </div>
            <div className="teamCard">
              <div className="teamImg">
                <MeierImg />
              </div>
              <h3 className="teamInfoHeadline">Martin Maier - Restaurator</h3>
              <p className="teamInfoP">
                Martin Maier hat an der Technischen Universität (TU) München
                Restaurierung, Kunsttechnologie und Konservierungswissenschaften
                studiert. Entsprechend der Anforderung wird er die
                Arbeitsbereiche Wand und Ausstattung abdecken. Eines seiner
                Studieninhalte war die Untersuchung der Barockfassade des
                Westflügels von Schloss Höllrich. Darüber hinaus hat Herr Maier
                eine Kopie von Bartolomé Estéban Murillo, „Christus als guter
                Hirte“ im Rahmen seines Studiums für ein niederländisches Museum
                restauriert.
              </p>
            </div>
            <div className="teamCard">
              <div className="teamImg">
                <TschoepeImg />
              </div>
              <h3 className="teamInfoHeadline">
                Ivonne Tschoepe - Restauratorin
              </h3>
              <p className="teamInfoP">
                Restauratorin Ivonne Tschoepe hat an der FH Potsdam Wandmalerei
                und Architekturfassung studiert. Sie hat praktische Erfahrungen
                in der Konservierung von mittelalterlichen Putzen und der
                Konservierung und Rekonstruktion von Stuckmarmor und
                verschiedensten Vergoldungsarbeiten. Im Bereich der Wandmalerei
                ist sie spezialisiert, Fassungen zu untersuchen, zu konservieren
                und restaurieren. Auch erstellt sie Farbkonzepte und entwirft
                Gestaltungen passend zum historischen Objekt und rekonstruiert
                diese in historischer Weise. Sie hat in größeren und auch
                kleineren qualifizierten Restauratorenteams in Berlin,
                Brandenburg, Sachsen, Sachsen-Anhalt, Bayern und auch im
                europäischen Ausland einen großen Erfahrungsschatz im Bereich
                der Wandmalereirestaurierung gesammelt. (Freie Mitarbeit in
                Berlin Deutsche Staatsoper (Stuckmarmor, Vergoldungen),
                Pergamonmuseum, Altes und Neues Museum, Schadowhaus
                (illusionistische Deckengestaltungen/Schablonierungen) in
                Berlin, Dom zu Brandenburg, Hoher Dom zu Augsburg).
              </p>
            </div>
            <div className="teamCard">
              <div className="teamImg">
                <KloebleImg />
              </div>
              <h3 className="teamInfoHeadline">Orestes Klöble - Stuckateur</h3>
              <p className="teamInfoP">
                Orestes Klöble ist Stuckateurmeister und vermittelte sein
                praktisches Wissen für Stuckrestauratoren an verschiedenen
                Institutionen u. a. am „Europäischen Ausbildungszentrum für
                Handwerker im Denkmalschutz“ in Venedig. Bei Stuck- und
                Putzrestaurierungen sowie bei historischen Arbeitsverfahren, wie
                Stuckmarmorergänzung und Kalklasuren wird Herr Klöble für uns
                tätig. Für den namhaften Restaurator Michael Hangleitner nahm er
                beispielsweise die Fehlstellenergänzung des Stuckmarmors am
                „Alten Mausoleum“ des Landgrafen zu Hessen in Darmstadt vor.
              </p>
            </div>
            <div className="teamCard">
              <div className="teamImg">
                <KljajicImg />
              </div>
              <h3 className="teamInfoHeadline">Sascha Kljajic - Stuckateur</h3>
              <p className="teamInfoP">
                Sascha Kljajic hat als Stuckmarmorspezialist in Baden
                Württemberg, u. a. in Schloss Solitude, hochrangige Projekte
                ausgeführt. Zuletzt arbeitete er an der Restaurierung des
                Stuckmarmors im Badhaus des Kurfürsten Carl Theodor (1768–1775)
                von Schloss Schwetzingen. Darüber hinaus übernimmt Herr Kljajic
                für uns Stuckbefestigungen, Rissschließungen und{" "}
                <nobr>Kalklasuren</nobr> in den Raumschalen. Auch plastische
                Stuckergänzungen und Antragstuck werden von ihm ausgeführt.
              </p>
            </div>
            <div className="teamCard">
              <div className="teamImg">
                <RibeiroImg />
              </div>
              <h3 className="teamInfoHeadline">Renato Ribeiro - Bauforscher</h3>
              <p className="teamInfoP">
                Renato Ribeiro studierte „Historische Bauforschung“ an der
                Oth-Regensburg. Da er umfangreiche Erfahrung mit dem
                CAD-Programm hat, kann er auf Anfrage die Bestandkartierung in
                diesem System vornehmen. Darüber hinaus bringt Herr Ribeiro
                durch seine Masterarbeit, über das ehemalige Brennereigebäude
                des Schlosses Alteglofsheim, wichtige Erfahrungen bei der
                Erstellung des Raumbuches und des denkmalpflegerischen
                Bindungsplanes mit.
              </p>
            </div>
            <div className="teamCard">
              <div className="teamImg">
                <EgerlandImg />
              </div>
              <h3 className="teamInfoHeadline">Sandra Egerland - Raumbuch</h3>
              <p className="teamInfoP">
                Sandra Egerland ist ausgebildete Dipl.- Ingenieurin (FH) und
                studierte an der HTWS-Zittau/Görlitz. Um sich denkmalpflegerisch
                fortzubilden, studiert sie aktuell „Historische Bauforschung“ an
                der OTH Regensburg. Ihr Wirkungsbereich ist u. a. die
                Raumbucherstellung mit entsprechenden Plänen und
                Visualisierungen, wie sie für Bauwerkuntersuchungen notwendig
                werden. Frau Egerland beherrscht die Programme CAD und Archicad.
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default IndexPage
